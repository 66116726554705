.input {
  min-height: 46px;
  max-height: 46px;
  border-radius: 7px;
  //border: 1px solid $card-border;
  //margin: 20px;
  padding: 0 26px 0 25px;
  font-size: 15px;

  &::placeholder {
    font-size: 15px;
    line-height: 21px;
    color: $placeholder;
  }
}

.ant-input {
  &::placeholder {
    font-size: 15px;
    line-height: 21px;
    color: $placeholder;
  }
}

textarea {
  //height: 206px !important;
  border-radius: 10px !important;
  //border: 1px solid $card-border !important;
  padding: 26px 26px 26px 25px !important;
  font-size: 15px !important;
  resize: unset;
}
