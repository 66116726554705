@import '/src/assets/scss/variable';

// Cover component
.bottomRow {
  background-color: #fa9303;
  color: #fafafa;
  align-items: center;
  justify-content: center;
  flex: 1;
}

.cover_component {
  width: 100%;
  height: 252px;

  .cover_image_wrapper {
    display: flex;
    align-items: center;
    width: 100%;
    height: 252px;
    overflow: hidden;
    position: relative;
    z-index: 1;

    .ant-empty {
      margin: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;

      .ant-empty-image {
        margin: 0;
      }
    }

    &:after {
      content: '';
      position: absolute;
      // background-image: url('../../src/assets/images/gradient.png');
      background: linear-gradient(180deg, rgba($white, 0) 40%, rgba($black, 0.8) 90%);
      width: 100%;
      height: 252px;
      bottom: 0;
      left: 0;
      z-index: 1;
    }

    .cover_image {
      width: 100%;
      min-height: 252px;
      height: auto;
    }

    .avatar_wrapper {
      display: flex;
      align-items: center;
      position: absolute;
      margin-left: 90px;
      z-index: 3;

      .ant-avatar {
        max-width: 119px;
        max-height: 119px;
        width: 145px;
        height: 145px;
        border: 3px solid $white;
        position: relative;

        .ant-image {
          .ant-image-img {
            width: 119px;
            height: 119px;
            object-fit: cover;
          }
        }
      }

      h2 {
        margin: 0 0 0 30px;
        color: $white;
      }

      .badge {
        background-color: $green;
        font-size: 12px;
        color: $white;
        padding: 3px 10px 2px 10px;
        border-radius: 3px;
        margin: 5px 0 0 30px;
        display: flex;
        align-items: center;
        justify-content: center;

        .icon_type {
          width: 12px;
          height: 15px;
          margin: 0 0 0 5px;
        }
      }

      .status-indicator {
        display: flex;
        position: absolute;
        left: 2px;
        bottom: -50px;
        z-index: 3;
        align-items: center;
        white-space: nowrap;
      }
      .status-circle {
        width: 10px;
        height: 10px;
        border-radius: 50%;
        margin-right: 5px;
      }

      .change_avatar_btn {
        padding: 5px 15px 7px 15px;
        border: 1px solid $white;
        border-radius: 8px;
        color: $white;
        font-size: 15px;
        font-weight: 400;
        margin-left: 30px;
        cursor: pointer;
      }
    }

    .upload_area {
      .ant-upload-list {
        display: none;
      }
    }

    .change_cover_btn_2 {
      display: flex;
      align-items: center;
      padding: 4px 12px 2px 10px;
      border: 1px solid $white;
      border-radius: 8px;
      background-color: unset;
      color: $white;
      font-size: 15px;
      font-weight: 400;
      cursor: pointer;
      position: absolute;
      right: 90px;
      bottom: 18px;
      z-index: 3;

      svg {
        // display: none;
        // margin: -3px 4px 0 0;
      }
    }

    .preview_button {
      display: flex;
      align-items: center;
      padding: 4px 12px 2px 10px;
      border: 1px solid $white;
      border-radius: 8px;
      background-color: unset;
      color: $white;
      font-size: 15px;
      font-weight: 400;
      cursor: pointer;
      position: absolute;
      right: 50px;
      bottom: 18px;
      z-index: 3;
    }

    .change_cover_btn {
      display: flex;
      align-items: center;
      padding: 4px 12px 2px 10px;
      border: 1px solid $white;
      border-radius: 8px;
      background-color: unset;
      color: $white;
      font-size: 15px;
      font-weight: 400;
      cursor: pointer;
      position: absolute;
      // right: 250px;
      bottom: 18px;
      z-index: 3;

      svg {
        // display: none;
        // margin: -3px 4px 0 0;
      }
    }

    .change_avatar_btn_sml {
      background-color: $primary;
      border-radius: 100px;
      border: unset;
      min-width: 30px;
      max-width: 30px;
      min-height: 30px;
      max-height: 30px;
      padding: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      left: 90px;
      bottom: 8px;
      cursor: pointer;

      svg {
        color: $white;
        font-size: 15px;
      }
    }
  }
}

@media only screen and (max-width: 820px) {
  .cover_component {
    .cover_image_wrapper {
      .avatar_wrapper {
        margin-left: 40px;
      }

      .change_cover_btn {
        right: 40px;
      }
    }
  }
}

@media only screen and (max-width: 600px) {
  .cover_component {
    width: 100%;
    height: 252px;

    .cover_image_wrapper {
      align-items: center;

      .change_cover_btn {
        right: 20px;
        bottom: 20px;
        z-index: 3;
        border: unset;
        padding: 0;
        font-size: 30px;

        span {
          display: none;
        }

        svg {
          display: block;
          margin: 0;
        }
      }

      .avatar_wrapper {
        display: flex;
        justify-content: center;
        left: 0;
        z-index: 3;
        flex-direction: column;
        width: 100%;
        margin: 0;

        .ant-avatar {
          max-width: 80px;
          max-height: 80px;
          border: 2px solid $white;
          position: relative;

          .ant-image {
            .ant-image-img {
              width: 80px;
              height: 80px;
              object-fit: cover;
            }
          }
        }

        .name-and-badge {
          width: 94%;
        }

        h2 {
          margin: 12px 0 0 0;
          font-size: 16px;
          text-align: center;
          color: $white;
        }

        .badge {
          display: table;
          font-size: 11px;
          padding: 2px 7px;
          margin: 6px auto 0 auto;

          .icon_type {
            width: 11px;
            height: 12px;
            margin: -2px 0 0 4px;
          }
        }

        .upload_area {
          position: relative;
          width: 80px;

          .ant-upload-select {
            .ant-upload {
              .change_avatar_btn_sml {
                min-width: 18px;
                max-width: 18px;
                min-height: 18px;
                max-height: 18px;
                top: -81px;
                right: 3px;
                left: inherit;

                svg {
                  font-size: 10px;
                }
              }
            }
          }
        }
      }
    }
  }
}

// Submenu component
// .sub_menu_wrapper {
//   width: 100%;
//   box-shadow: 0px 4px 4px rgba($black, 0.1);
//   margin-bottom: 20px;

//   .menu_container {
//     padding: 0 90px;
//     display: flex;
//     align-items: center;
//     justify-content: space-between;
//     height: 66px;

//     ul {
//       &.sub_menu {
//         width: calc(100% - 200px);
//         border: unset;

//         .ant-menu-item {
//           padding: 0;
//           margin: 0 30px 0 0;

//           &:hover {
//             border: unset;

//             &:after {
//               border: unset;
//             }
//           }

//           .ant-menu-title-content {
//             font-size: 15px;
//             color: #3c3c3c;
//           }

//           &.active {
//             .ant-menu-title-content {
//               font-weight: 700;
//               color: $primary;
//               border-bottom: 3px solid $primary;
//               padding-bottom: 3px;
//             }
//           }
//         }

//         .ant-menu-item-active {
//           &:after {
//             display: none;
//           }
//         }

//         .ant-menu-item-selected {
//           &:after {
//             border: unset;
//           }
//         }
//       }
//     }

//     .copy_clipboard_btn {
//       height: 38px;
//       padding: 0 12px;
//       border: 1px solid $black83;
//       display: flex;
//       align-items: center;
//       justify-content: center;
//       border-radius: 7px;
//       color: $black83;

//       span {
//         display: flex;
//         align-items: center;
//         font-size: 14px;
//         line-height: 17px;

//         svg {
//           font-size: 14px;
//           color: $black83;
//           margin-right: 5px;
//         }
//       }
//     }
//   }
// }

// Under review component
.under_review_component {
  width: 100%;
  min-height: 44px;
  background-color: $orange;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 11px 20px;

  h6 {
    font-size: 14px;
    line-height: 17px;
    font-weight: 600;
    color: $white;
    margin: 0;
    text-align: center;
  }
}

// Responsive
@media only screen and (max-width: 600px) {
  .under_review_component {
    padding: 10px 15px;

    h6 {
      font-weight: normal;
      font-size: 12px;
      line-height: 15px;
      letter-spacing: 0;
    }
  }
}
