.ant-layout-header {
  background: $white;
  padding: 0 90px;
}

.header {
  display: flex;
  align-items: center;
  height: 60px; /* 80px */
  background: $white;
  box-shadow: 0px 3px 3px rgba($black, 0.1);
  border-bottom: 1px solid rgba($black, 0.1);

  .logo {
    cursor: pointer;
    // height: 24px;
    height: 2rem;
    width: 12rem;
  }

  .menu_area {
    width: calc(100% - 200px);
    // width: auto;
    display: flex;
    align-items: center;
    margin-left: auto;

    .overflowedIndicator {
      padding-top: 5px;
    }

    ul {
      margin-left: auto;
      min-width: 470px; /* 700px */
      border: unset;
      display: flex;
      flex: 1;
      align-items: center;
      justify-content: flex-end;
      background-color: unset;

      li {
        .ant-menu-title-content {
          display: flex;
          align-items: center;
          font-size: 15px;
          line-height: 20px;
          font-weight: $w4;

          svg {
            font-size: 17px;
            margin: 0 10px 0 0;
          }

          .unreadCount {
            min-width: 19px;
            // max-width: 19px;
            min-height: 19px;
            // max-height: 19px;
            margin: 0 0 0 8px;
            padding: 0;
            font-size: 11px;
            line-height: 14px;
            color: $white;
            text-align: center;
            background-color: $green;
            border: unset;
            border-radius: 4px;
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }

        &.ant-menu-item {
          &:hover {
            color: $black;
          }
        }

        &.ant-menu-item-selected {
          color: $primary;

          &:hover {
            color: $primary;
          }
        }

        &:after {
          display: none;
        }
      }
    }

    .profile_btn_area {
      border-left: 1px solid $card-border;
      padding-left: 25px;

      .profile_btn {
        display: flex;
        align-items: center;
        // justify-content: center;
        font-size: 15px;
        line-height: 20px;
        color: $black;
        border-radius: 10px;
        height: 42px;
        padding: 2px 0px 2px 0px;
        position: relative;
        z-index: 100;

        .ant-avatar {
          width: 42px;
          height: 42px;
          margin: 0 10px 0 0;
        }
      }
    }
  }
}

.ant-dropdown {
  z-index: 1;

  ul {
    &.dropdown-menu {
      border-radius: 0 0 10px 10px;
      margin-top: -11px;
      padding: 9px 0 0 0;
      box-shadow: 0px 3px 4px rgba($black, 0.25);
      overflow: hidden;

      li {
        padding: 0;

        .ant-dropdown-menu-title-content {
          a {
            font-size: 15px;
            display: flex;
            align-items: center;
            padding: 10px 20px;

            svg {
              margin-right: 10px;
            }
          }
        }

        &:nth-child(1) {
          .ant-dropdown-menu-title-content {
            a {
              svg {
                font-size: 15px;
                margin-left: -2px;
              }
            }
          }
        }
      }
    }
  }
}

// Responsive
@media only screen and (max-width: 1000px) {
  .header {
    .menu_area {
      width: calc(100% - 120px);

      .ant-menu {
        min-width: 420px;

        .ant-menu-item {
          padding: 0 15px;

          &:nth-child(4) {
            padding-right: 15px;
          }

          .ant-menu-title-content {
            svg {
              margin: 0 7px 0 0;
            }
          }
        }
      }

      .profile_btn_area {
        padding-left: 20px;
      }
    }
  }
}

@media only screen and (max-width: 900px) {
  .header {
    .menu_area {
      .ant-menu {
        .ant-menu-item {
          padding: 0 10px;

          .ant-menu-title-content {
            font-size: 14px;

            svg {
              margin: -1px 4px 0 0;
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 800px) {
  .header {
    padding: 0 40px;
    position: relative;

    .menu_area {
      // flex-direction: row-reverse;
      width: calc(100% - 110px);

      .ant-menu {
        width: 54px;
        min-width: initial;
        margin-left: 0;

        .ant-menu-overflow-item {
          padding: 3px 0 0 0;

          .ant-menu-submenu-title {
            svg {
              width: 35px;
              height: 40px;
              color: $black83;
              margin: 4px 0 0 18px;
            }
          }
        }

        .ant-menu-submenu {
          height: 60px;
          text-align: right;
          padding-right: 20px;
        }
      }

      .profile_btn_area {
        padding-left: 0;
        border-left: 0;
      }
    }
  }
  .ant-dropdown {
    width: 100%;

    ul {
      &.dropdown-menu {
        margin-top: 6px;
        padding-bottom: 0px;
        padding-top: 0;

        .ant-dropdown-menu-item {
          border-bottom: 1px solid rgba($black, 0.1);

          &:nth-last-child(1) {
            border-bottom: unset;
          }
        }
      }
    }
  }
  .ant-menu-submenu {
    width: 100%;

    .ant-menu {
      position: relative;
      top: -21px !important;
      left: 0 !important;

      .ant-menu-item {
        padding: 0 36px;

        .ant-menu-title-content {
          display: flex;
          align-items: center;

          svg {
            margin: 0 10px 0 0;
          }

          a {
            &:hover {
              color: $primary !important;
            }
          }

          .unreadCount {
            min-width: 19px;
            min-height: 19px;
            margin: 0 0 0 8px;
            padding: 2px 3px 2px 2px;
            font-size: 11px;
            line-height: 14px;
            color: $white;
            text-align: center;
            background-color: $green;
            border: unset;
            border-radius: 4px;
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
      }

      .ant-menu-item-selected {
        background-color: unset;

        .ant-menu-title-content {
          color: $primary;

          a {
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 600px) {
  .header {
    padding: 0 20px;

    .menu_area {
      .profile_btn_area {
        .profile_btn {
          .ant-avatar {
            margin: 0 -5px 0 0;
            width: 36px;
            height: 36px;
          }

          .sp_hide {
            display: none;
          }

          svg {
            display: none;
          }
        }
      }
    }
  }
  .ant-dropdown {
    ul {
      &.dropdown-menu {
        margin-top: 6px;
        border-radius: 0;
      }
    }
  }
  .ant-menu-submenu {
    .ant-menu {
      .ant-menu-item {
        padding: 0 20px;
      }
    }
  }
}
