.ant-btn {
  font-size: 15px;
  line-height: 20px;
  font-weight: $w5;
  padding: 8px 18px;
  height: 38px;
  border-radius: 4px;

  &.ant-btn-primary {
    background-color: $primary;
    border-color: $primary;
  }
}
