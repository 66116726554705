.main-text-p {
  margin: 0;
  font-weight: 700;
}

.sub-text-p {
  color: gray;
}

.sub-text-c {
  color: gray;
  margin-bottom: 0 !important;
}

.copy-icon {
  display: inline;
  margin-left: 5px;
  margin-top: 5px;
  cursor: pointer;
  color: gray;
}

@media screen and (max-width: 768px) {
  .main-text-p {
    margin: 0;
    font-weight: 600;
  }
}
