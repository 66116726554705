.multiple-select-button {
  width: 328px !important;
  height: 50px !important;
  flex-shrink: 0;
  border-radius: 10px !important;
  border: 1px solid #b9b9b9 !important;
  background: #fff !important;
  text-align: center;

  // .dropdown-menu {
  //   width: 328px;
  //   border-radius: 0 0 10px 10px;
  // }
}

@media (max-width: 768px) {
  .multiple-select-button {
    width: 100% !important;
    height: 40px !important;
  }
}
