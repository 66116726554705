.ant-layout {
  width: 100%;
  background-color: $white;

  main {
    // min-height: calc(100vh - 120px);
    background-color: $white;
    margin-bottom: 40px;
  }
}

.main_content {
  padding: 0 50px;
}

.fullPageLayout {
  min-height: calc(100vh - 120px);
}

@media only screen and (max-width: 480px) {
  .main_content {
    padding: 0 20px;
  }
}
