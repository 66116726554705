@import '/src/assets/scss/variable';

.register {
  margin: 35px 0 45px 0;
  padding-bottom: 50px;

  .card {
    border: 1px solid $card-border;

    .ant-card-body {
      padding: 45px 55px;

      .form {
        .form_item_area {
          display: flex;
          flex-wrap: wrap;

          .ant-form-item {
            display: block !important;
            align-items: center;
            width: calc((100% - 60px) / 2);
            margin-bottom: 0;

            &:nth-child(odd) {
              margin-right: 60px;
            }

            &:nth-last-child(1) {
              width: 100%;
              margin-right: 0;

              .ant-form-item-control-input-content {
                display: flex;
                align-items: center;
              }
            }

            &:nth-last-child(2) {
              width: 100%;
              margin-right: 0;
            }

            .ant-input-textarea-show-count {
              &:after {
                float: left;
                margin-top: 10px;
              }
            }

            .ant-form-item-label {
              display: block;
              text-align: left;

              label {
                font-size: 15px;
                line-height: 23px;
                font-weight: $w7;
                margin-bottom: 9px;
                height: 23px;
              }
            }

            .ant-form-text {
              font-size: 15px;
              line-height: 35px;
              font-weight: $w7;
              background-color: rgba($black, 0.05);
              width: 100%;
              display: block;
              height: 46px;
              border-radius: 7px;
              padding: 0 25px;
              display: flex;
              align-items: center;
            }

            .ant-btn-primary {
              margin-left: auto;
            }

            .select_interests {
              position: relative;

              &:after {
                content: '';
                background-image: url('../../../../src/assets/images/icon-select-arrow.svg');
                background-position: center center;
                display: block;
                width: 17px;
                height: 11px;
                position: absolute;
                top: 18px;
                right: 15px;
                transform: scale(0.8);
              }

              .ant-select-selector {
                padding: 0 15px;
                height: 46px !important;
                border-radius: 7px !important;

                .ant-select-selection-overflow {
                  font-size: 15px;

                  .ant-select-selection-overflow-item {
                    .ant-select-selection-item {
                      margin: 0 10px 0 0;
                      border-radius: 40px;
                      background-color: rgba($primary, 0.15);
                      border: unset;

                      .ant-select-selection-item-content {
                        display: flex;
                        align-items: center;
                        font-size: 15px;
                        line-height: 24px;
                        margin: 0 7px 0 0;

                        img {
                          width: 20px;
                          height: 20px;
                          margin: 0 6px 0 3px !important;
                        }
                      }

                      .ant-select-selection-item-remove {
                        font-size: 10px;
                        margin: 0 1px 0 0;
                        color: rgba($black, 0.4);

                        .anticon-close {
                          min-width: 16px;
                          max-width: 16px;
                          min-height: 16px;
                          max-height: 16px;
                          border: 1px solid rgba($black, 0.4);
                          border-radius: 50px;
                          padding: 2px;
                        }
                      }
                    }
                  }
                }
              }
            }

            .select_country {
              position: relative;

              &:after {
                content: '';
                background-image: url('../../../../src/assets/images/icon-select-arrow.svg');
                background-position: center center;
                display: block;
                width: 17px;
                height: 11px;
                position: absolute;
                top: 18px;
                right: 15px;
                transform: scale(0.8);
              }

              .ant-select-arrow {
                display: none;
              }

              .ant-select-selector {
                padding: 0 20px;
                height: 46px !important;
                border-radius: 7px !important;

                .ant-select-selection-search {
                  //
                }

                .ant-select-selection-item {
                  display: flex;
                  align-items: center;
                  font-size: 15px;
                }
              }
            }
          }

          textarea {
            width: 100%;
            height: 150px !important;
          }

          .form_btn_area {
            display: flex;
            align-items: center;
            width: 100%;
            margin-top: 30px;

            .btn_area {
              margin-left: auto;

              .btn_link {
                border: unset;
                background-color: unset;
                cursor: pointer;
                margin-right: 16px;
                font-size: 15px;
              }
            }

            .form_edit_text {
              font-size: 15px;
              text-align: left;
            }

            .ant-btn-primary {
              margin-left: auto;
            }
          }
        }
      }
    }
  }

  // Modal styles
  .ant-modal {
    .ant-modal-content {
      border-radius: 7px;

      .ant-modal-close {
        display: none;
      }

      .ant-modal-body {
        padding: 30px 30px 38px 30px;

        .ant-row {
          .ant-col {
            margin: 0 auto;

            .check_icon {
              svg {
                width: 36px;
                height: 36px;
                color: $green;
              }
            }
          }
        }

        h2 {
          font-size: 20px;
          line-height: normal;
          text-align: center;
          margin: 10px auto 0 auto;
        }

        p {
          font-size: 15px;
          color: #676767;
          margin-top: 7px;
          text-align: center;
        }

        .border_box {
          border: 1px solid rgba($placeholder, 0.8);
          border-radius: 5px;
          padding: 15px 15px;
          display: flex;
          align-items: center;

          .eye_icon {
            margin: 0 12px 0 3px;
            width: 28px;
            height: 20px;
            color: $green;
          }

          .text {
            font-size: 14px;
            line-height: 18px;
          }
        }

        .ant-btn-primary {
          display: block;
          margin: 25px auto 0 auto;
        }
      }

      .ant-modal-footer {
        display: none;
      }
    }
  }

  // Interests
  .ant-select-dropdown {
    padding: 0;
    border-radius: 0 0 10px 10px;
  }
}

@media only screen and (max-width: 480px) {
  .register {
    .card {
      .ant-card-body {
        padding: 20px;
      }

      .form {
        .form_item_area {
          .form_btn_area {
            .btn_area {
              margin-top: 15px !important;
            }
          }
        }
      }
    }
  }
}
