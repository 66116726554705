$font-primary: 'Roboto', sans-serif;
// 400, 500, 700

$white: #fff;
$black: #000;
$primary: #005993;
$primary-tab: #1a6a9e;
$primary-light: #ACDDDE;
$border-gray: #d8d8d8;
$card-border: #B9B9B9;
$gray-text: #828282;
$gray-bg: #F5F6F7;
$gray-light: #EBEBEB;
$gray-medium: #F0F0F0;
$chat-bg: #e5ddd5;
$green: #019C58;
$black54: #545454;
$blackc4: #C4C4C4;
$yellow: #F8B623;
