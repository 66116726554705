.custom-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  z-index: 1000;
}

.custom-modal {
  top: 10%;
  background: #fff;
  padding: 30px;
  border-radius: 5px;
  width: 80%;
  max-width: 700px;
  position: relative;
  z-index: 1001;
  overflow-y: auto;

  @media (min-width: 768px) {
    // Medium devices
    width: 70%;
  }

  @media (min-width: 992px) {
    // Large devices
    width: 60%;
  }
}

.custom-modal-header {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .custom-modal-title {
    font-size: 20px;
    font-weight: bold;
  }

  .custom-modal-close {
    font-size: 20px;
    cursor: pointer;
  }
}

// .custom-modal-content {
//   padding: 10;
// }
