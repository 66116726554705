@import '/src/assets/scss/variable';

.stat_page {
  margin: 30px 0 0 0;

  .stat_row_area {
    display: flex;
    //flex-wrap: wrap;
    // column-gap: 15px;
    // row-gap: 15px;

    .stat_single {
      min-width: calc((100% - 45px) / 4);

      .card {
        // min-height: 150px;
        // min-width: 300px;
        border: unset;
        box-shadow: 0px 4px 9px rgba($black, 0.06);
        margin-bottom: 10px;
        transition: transform 0.3s ease-in-out, background-color 0.3s ease-in-out;

        &:hover {
          transform: scale(1.05);
          background-color: #e5e4e2;
          cursor: pointer;
        }

        .ant-card-body {
          padding: 20px 24px;

          .stat_card_title {
            display: flex;
            align-items: center;
            font-size: 20px;
            line-height: 24px;
            font-weight: 700;

            .green {
              color: #3da800;
            }

            .red {
              color: #db4e4e;
            }

            .orange {
              color: #d29a1e;
            }

            .cyan {
              color: #4ab5ae;
            }

            .black {
              color: $black;
            }

            .icon {
              width: 26px;
              height: auto;
              max-height: 25px;
              color: $card-border;
              margin-left: auto;
            }

            .share_select {
              margin-left: auto;

              .ant-select-selector {
                width: 58px;
                min-height: 25px;
                max-height: 25px;
                font-size: 11px;
                padding: 0 0 0 7px;
                border: 1px solid $black;
              }

              .ant-select-arrow {
                right: 7px;
                margin-top: -4px;

                .anticon {
                  svg {
                    width: 9px;
                    height: 9px;
                    color: $black;
                  }
                }
              }
            }
          }

          .details {
            font-size: 15px;
            margin: 1px 0 0 0;
          }

          .row {
            display: flex;
            align-items: flex-end;
            margin: 20px 0 0 0;

            .ant-btn {
              padding: 0 8px;
              height: 25px;
              border: 1px solid $black3a;
              border-radius: 4px;
              font-size: 11px;
              line-height: 25px;
              color: $black3a;
              font-weight: 400;
            }

            .info_icon {
              font-size: 20px;
              color: $text-gray;
              margin-left: auto;
            }
          }
        }
      }
    }
  }

  .facebookIcon {
    color: rgb(59, 89, 152);
  }
}

// Modal styles
.ant-modal {
  .ant-modal-content {
    border-radius: 7px;

    .ant-modal-close {
      width: 64px;
      height: 72px;

      .ant-modal-close-x {
        height: 34px;

        svg {
          width: 16px !important;
          height: 16px !important;
        }
      }
    }

    .ant-modal-header {
      border-radius: 7px 7px 0 0;
      padding: 35px 30px 20px 30px;
      border: unset;

      .ant-modal-title {
        font-size: 20px;
        font-weight: $w7;
      }
    }

    .ant-modal-body {
      //padding: 0px 30px 24px 30px;

      .ant-table-wrapper {
        .ant-table {
          .ant-table-container {
            .ant-table-content {
              table {
                thead {
                  tr {
                    th {
                      background-color: unset;
                      font-size: 15.5px;
                      font-weight: $w7;

                      &:before {
                        display: none;
                      }

                      &:nth-child(1) {
                        padding: 16px 5px;
                      }
                    }
                  }
                }

                tbody {
                  td {
                    &:nth-child(1) {
                      padding: 16px 5px;
                    }
                  }
                }
              }
            }
          }
        }

        &.help_table {
          //
        }
      }
    }
  }
}

@media screen and (min-width: 810px) and (max-width: 1100px) {
  .shareButtonCustom {
    position: absolute !important;
    bottom: 18px !important;
    left: 12px !important;
    z-index: 999;
  }
}

// Responsive
@media only screen and (max-width: 1444px) {
  .stat_page {
    .stat_row_area {
      .stat_single {
        min-width: calc((100% - 30px) / 3);
      }
    }
  }
}

@media only screen and (max-width: 1130px) {
  .stat_page {
    .stat_row_area {
      .stat_single {
        min-width: calc((100% - 15px) / 2);
      }
    }
  }
}

@media only screen and (max-width: 820px) {
  .gray_bg .ant-layout-content .main_content {
    padding: 0 40px !important;
  }
}

@media only screen and (max-width: 715px) {
  .stat_page {
    .stat_row_area {
      .stat_single {
        min-width: 100%;
      }
    }
  }
}

@media only screen and (max-width: 600px) {
  .stat_page {
    margin-top: 20px;
  }
  .gray_bg .ant-layout-content .main_content {
    padding: 0 20px !important;
  }
}
