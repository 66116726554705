@import '/src/assets/scss/variable';

.resendBtn {
  font-weight: normal;
  border: none;
  text-decoration: underline !important;
}

.createMyProfileBtn {
  margin-right: 10px;
  color: white;
}

.changeMyNumberBtn {
  font-weight: normal;
  border: none;
}

.ant-modal {
  .ant-modal-content {
    border-radius: 7px;

    .ant-modal-close {
      display: none;
    }

    .ant-modal-body {
      padding: 30px 30px 38px 30px;

      .ant-row {
        .ant-col {
          margin: 0 auto;

          .check_icon {
            svg {
              width: 36px;
              height: 36px;
              color: $green;
            }
          }
        }
      }

      h2 {
        font-size: 20px;
        line-height: normal;
        text-align: center;
        margin: 10px auto 0 auto;
      }

      p {
        font-size: 15px;
        color: #676767;
        margin-top: 7px;
        text-align: center;
      }

      .border_box {
        border: 1px solid rgba($placeholder, 0.8);
        border-radius: 5px;
        padding: 15px 15px;
        display: flex;
        align-items: center;

        .eye_icon {
          margin: 0 12px 0 3px;
          width: 28px;
          height: 20px;
          color: $green;
        }

        .text {
          font-size: 14px;
          line-height: 18px;
        }
      }

      .ant-btn-primary {
        display: block;
        margin: 25px auto 0 auto;
      }
    }

    .ant-modal-footer {
      // display: none;
    }
  }
}
