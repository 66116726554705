.step_area {
  margin: 35px 0 32px 0;
  display: block;

  .ant-steps-item {
    .ant-steps-item-container {
      .ant-steps-item-icon {
        width: 23px;
        height: 23px;
        // background-color: $primary;
        // border-color: $primary;
        .ant-steps-icon {
          font-size: 15px;
          font-weight: $w7;
          color: $white;
          top: -6px;
        }
      }

      .ant-steps-item-content {
        .ant-steps-item-title {
          font-size: 15px;
          line-height: 25px;
          font-weight: $w4;
        }
      }
    }

    &.ant-steps-item-active {
      .ant-steps-item-container {
        .ant-steps-item-icon {
          background-color: $primary;
          border-color: $primary;
        }
      }

      .ant-steps-item-content {
        .ant-steps-item-title {
          color: $primary;
        }
      }
    }

    &.ant-steps-item-wait {
      .ant-steps-item-container {
        .ant-steps-item-icon {
          background-color: unset;
          border-color: $text-gray;

          .ant-steps-icon {
            color: $text-gray;
            font-weight: $w4;
          }
        }
      }

      .ant-steps-item-content {
        .ant-steps-item-title {
          color: $text-gray;
        }
      }
    }

    &.ant-steps-item-finish {
      .ant-steps-item-container {
        .ant-steps-item-icon {
          background-color: $white;
          border-color: $green;

          .ant-steps-icon {
            svg {
              color: $green;
              font-size: 13px;
            }
          }
        }
      }
    }
  }
}
