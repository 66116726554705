@import '/src/assets/scss/variable';

.login_header {
  position: absolute;
  top: 0;

  .logo {
    img {
      height: 50%;
      width: 70%;
      margin-bottom: 10%;
    }
  }
}

.login_page {
  min-height: calc(100vh - 160px);
  // padding: 125px 0 50px 0;
  display: flex;
  align-items: center;
  justify-content: center;

  .page_title {
    font-size: 22px;
    line-height: 30px;
    font-weight: $w7;
    text-align: center;
  }

  .card {
    width: 400px;
    margin-top: 30px;
    box-shadow: 0 1px 1px 0 rgb(0 0 0 / 10%), 0 3px 20px 0 rgb(0 0 0 / 15%);
    border: none;

    .ant-card-body {
      padding: 45px 45px 15px 45px;

      form {
        .ant-form-item {
          display: block;
          margin-bottom: 0;
          margin: 0 0 25px 0;
          // margin-right: 22px;
          .input-and-icons {
            display: flex;
            font-size: 20px;
            align-items: center;
          }

          .ant-col {
            label {
              font-size: 15px;
              line-height: 15px;
              font-weight: $w4;
              margin: 0 0 0px 0;
              height: auto;
            }

            .ant-form-item-explain {
              min-height: unset;
            }
          }
        }

        .check_area {
          display: flex;
          align-items: center;

          .forgot_link {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            margin-top: -5px;
            text-decoration: underline;
            cursor: pointer;
            margin-left: auto;

            a {
              font-size: 15px;
              line-height: 20px;
              color: $primary;
              font-weight: $w4;
              text-decoration: underline;
              margin-left: auto;
            }
          }

          .remember_check {
            margin-top: -5px;

            .ant-checkbox-wrapper {
              .ant-checkbox-checked {
                .ant-checkbox-inner {
                  background-color: $primary;
                  border-color: $primary;
                  border-radius: 3px;
                }
              }
            }
          }
        }

        .ant-btn-primary {
          width: 100%;
          display: block;
          margin: 20px auto 0 auto;
        }
      }
    }
  }
}

// Responsive
@media only screen and (max-width: 440px) {
  .login_page {
    padding: 0 20px;

    .page_title {
      font-size: 18px;
    }

    .ant-col {
      width: 100%;

      .card {
        width: 100%;
      }
    }
  }
}

@media only screen and (max-width: 400px) {
  .login_page {
    .ant-col {
      .card {
        .ant-card-body {
          padding: 30px 25px 5px 25px;
        }
      }
    }
  }
}
