.btn_link {
  margin-right: 10px;
  background-color: white;
  border-color: #d9d9d9;
  padding: 5px;
  border-radius: 5px;
  border-width: 1px;
}

.profile-input {
  .ant-form-item-label {
    label {
      font-weight: bold;
    }
  }

  .PhoneInputInput {
    border: 0;
  }

  .PhoneInput--focus {
    input {
      outline: none;
    }
  }
  .input {
    padding: 0 12px !important;
  }
}

.ant-select-multiple .ant-select-selector {
  padding: 0 12px !important;
}

.ant-picker-cell-start:not(.ant-picker-cell-disabled) {
  color: rgba(0, 0, 0, 0.85) !important;
}
.ant-picker-cell-end:not(.ant-picker-cell-disabled) {
  color: rgba(0, 0, 0, 0.85) !important;
}

.mt-lg {
  margin-top: 1rem;
}

.form_btn_area {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.ant-select .ant-select-selector {
  overflow-y: auto !important;
}

@media only screen and (max-width: 480px) {
  .form_btn_area {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .form_edit_text {
      width: 185px;
    }

    .btn_area {
      display: flex;
      margin-top: 20px !important;

      .btn_link {
        margin-right: 10px;
        background-color: white;
        border-color: #d9d9d9;
        padding: 5px;
        border-radius: 5px;
        border-width: 1px;
      }
    }
  }
}
