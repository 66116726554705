.ant-select {
  .ant-select-selector {
    align-items: center;
    min-height: 46px;
    max-height: 46px;
    border-radius: 5px !important;
    //border: 1px solid $card-border !important;
    // padding: 26px 26px 26px 25px !important;
    font-size: 15px;
    height: 30px !important;
  }

  .ant-select-arrow {
    right: 15px;

    svg {
      font-size: 13px;
    }
  }
}

// .input_phone {
//     .ant-input-wrapper {
//         .ant-input-group-addon {
//             background-color: unset;
//             border: unset;
//             padding: 0;
//             .ant-form-item {
//                 margin: 0 !important;
//                 .ant-form-item-control {
//                     .ant-form-item-control-input {
//                         .ant-form-item-control-input-content {
//                             .antd-country-phone-input {
//                                 background-color: unset;
//                                 border: unset;
//                                 padding: 0;
//                                 .ant-input-prefix {
//                                     margin-right: 0;
//                                     span {
//                                         .ant-select {
//                                             margin: 0;
//                                             .ant-select-selector {
//                                                 border-radius: 10px 0 0 10px !important;
//                                                 width: 164px;
//                                                 padding: 23px 26px 26px 2px !important;
//                                                 .ant-select-selection-item {
//                                                     padding: 0;
//                                                 }
//                                             }
//                                             .ant-select-arrow {
//                                                 right: 10px;
//                                                 .anticon {
//                                                     svg {
//                                                         font-size: 15px;
//                                                     }
//                                                 }
//                                             }
//                                         }
//                                     }
//                                 }
//                                 .ant-input {
//                                     display: none;
//                                 }
//                             }
//                         }
//                     }
//                 }
//             }
//         }
//         .ant-input {
//             height: 80px;
//             border-radius: 10px;
//             border: 2px solid $card-border;
//             padding: 26px 26px 26px 25px;
//             font-size: 25px;
//             border-radius: 0 10px 10px 0;
//             border-left: unset;
//         }
//     }
// }
