// Colors
$primary: #005994;
$white: #fff;
$black: #000;
$footer-bg: #ECECEC;
$card-border: #B9B9B9;
$placeholder: #828282;
$text-gray: #C4C4C4;
$black83: #838383;
$orange: #FC9403;
$black3a: #3A3A3A;
$green: #019C58;

//  Font weight
$w1: 100;
$w3: 300;
$w4: 400;
$w5: 500;
$w7: 700;
$w9: 900;
