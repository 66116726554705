@import '/src/assets/scss/variable';

.intro-video-modal {
  .videoSection {
    border-radius: 5px;
    max-width: 100%;
    width: auto;
    height: 100%;
    margin: 0 auto;
  }

  .ant-modal-content {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .ant-modal-body {
    padding: 10px !important;
    height: calc(100vh - 50px) !important;
  }
}

.ant-modal {
  top: 20px;
}
